export const LoaderTwister = () => {
  return (
    <figure>
      <div className={"dot white_dot"}></div>
      <div className={"dot"}></div>
      <div className={"dot"}></div>
      <div className={"dot"}></div>
      <div className={"dot"}></div>
    </figure>
  );
};
